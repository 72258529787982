import React, { useState } from 'react';
import axios from "axios";


const App = () => {
  const [InputS3URI, setField1] = useState('');
  const [OutputS3URI, setField2] = useState('');
  const [CustomizationMap, setField3] = useState('[]');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      "InputS3URI":InputS3URI,
      "OutputS3URI":OutputS3URI,
      "CustomizationMap":JSON.parse(CustomizationMap),
    };

    const url = 'https://cbai-data-porter-ap-southeast-2-future.devai.curvebeamai.com/anonymize';

    axios.post(url, data)
    .then(function (response) {
      console.log('Response: ', response);
      alert(response.data.message);
    })
    .catch(function (error) {
      console.log('Error:', error);
      alert("Error: " + error);
    });
  };

  return (

    <div className="alignText">
      <p></p>
      <form id="myForm" onSubmit={handleSubmit} className="Table">
        <div className="Row">
          <label className="Cell" htmlFor="field1">
            Input S3 URI:
          </label>
          <input
              className="Cell"
              id="field1"
              type="text"
              value={InputS3URI}
              onChange={(e) => setField1(e.target.value)}
            />
        </div>
        <div className="Row">
          <label className="Cell" htmlFor="field2">
            Output S3 URI:
          </label>
          <input
              className="Cell"
              id="field2"
              type="text"
              value={OutputS3URI}
              onChange={(e) => setField2(e.target.value)}
              required
            />
        </div>
        <div className="Row">
          <label className="Cell" htmlFor="field3">
            Anonymization Customization:
          </label>
          <textarea
              className='textArea'
              rows="30"
              cols="50"
              id="field3"
              resize= "none"
              type="text"
              placeholder='Add empty list [] for no customization'
              value={CustomizationMap}
              onChange={(e) => setField3(e.target.value)}
              required
            />
        </div>
      </form>
      <p></p>
      <button type="submit" form='myForm' style={{width: '150px', height: '30px'}}>Anonymize!</button>
    </div>
  );
};

export default App;
