import React from 'react';

const logo = require('./cbai_logo_new.png');

const HomePage = () => {
  return (
    <div>
      <header>
          <img src={logo} alt="Logo" height={200} className="alignBlock"/>
      </header>

      <main>
        <h1 className="alignText">Dicom Anonymizer</h1>
      </main>
    </div>
  );
}

export default HomePage;
