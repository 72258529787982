import React from 'react';
import HomePage from './components/HomePage';
import TestAnonymizer from './components/TestAnonymizer';

function App() {
  return (
    <div>
      <HomePage />
      <TestAnonymizer />
    </div>
  );
}

export default App;
